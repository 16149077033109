.nav-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    height: auto;
    font-size: 1.5rem;
    padding: .5rem 1rem;
}

.nav-logo:before {
    content: '{ ';
}

.nav-logo {
    font-family: 'Montserrat', sans-serif;
}

.nav-logo:after {
    content: ' }';
}

.btn {
    /* the tricks */
    transform: rotate(90deg);
    border: none;
   
    color: rgb(121, 121, 121);
    background-color: transparent;
    /*transition: 2s ease-in-out;*/
}

.btn:active {
    transform: scale(90%) rotate(90deg);
}

.hamburger {
    line-height: 10px;
}

.nav-links {
    flex-basis: 100%;
    flex-grow: 1;
    overflow: hidden;
    align-items: center;
    height: 7rem;
    transition: height .5s ease-out;
}


.no-show {
    position: relative; 
    height: 0;
    transition: height .5s ease-out;
}

@media screen and (min-width: 768px) {
    
    .nav-header {
        flex-direction: row;
        justify-content: flex-start;
    }
    
    .nav-links {
        overflow: visible;
        flex-basis: 0;
        flex-grow: 0;
        height: 2rem;
    }
    
    .nav-btn {
        display: none;
    }
}