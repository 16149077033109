.contact-section {
    background-color: var(--main-bg-color);
    display: grid;
    height: 30vh;
    place-items: center;
    width: 100vw;
}

.contact-title {
    color: var(--main-font-color);
    font-family: 'Montserrat', sans-serif;
    font-size: 2.25rem;
    text-transform: capitalize;
}


.contact-list {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    list-style: none;
    padding: 0;
    width: 60vw;
}

.contact-list-li i {
    color: var(--fawe-icons-color);
    font-size: 3rem;
    transition: transform 0.5s 0.2s, color 0.8s 0.5s;
}

.contact-list-li i:hover {
    transform: scale(120%);
    
}

.fa-twitter:hover {
    color:rgb(0, 4, 255);
}

.fa-linkedin:hover {
    color:rgb(12, 12, 36);
}

.fa-github:hover {
    color:rgb(255, 0, 0);
}

@media screen and (min-width: 768px) {
    
    .contact-list {
        width: 30vw;
    }
    

}