.who-is {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    width:100%;
    height: 50vh;
    min-height: 40vh;
}




@media screen and (min-width: 767px) {
    .who-is {
        border: none;    
    }
}