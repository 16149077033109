.nav-ulist {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    margin-top: 0;
    list-style: none;
}

.nav-link {
    font-size: 1.1rem;
    letter-spacing: 0.1rem;
    
}




@media screen and (min-width: 768px) {
    .nav-ulist {
        flex-direction: row;
        justify-content: space-around;     
    }

    .nav-link {
        margin: 0 2rem;
        font-size: 1.1rem;
    }
}

