.fe-tech {
    background-color: var(--main-bg-color-dark);
    text-align: center;
    height: 20vh;
}

.img-container {
    margin: 2rem auto;
}
.img-list {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    list-style: none;
    padding: 0;
} 

.img-list-li {
    font-size: 3rem;
    color: #fff;
    animation: animate 5s linear infinite;
    text-shadow: 0 0 1.5rem #0072ff, 0 0 2rem #0072ff, 0 0 2.5rem #0072ff, 0 0 3rem #0072ff;
}

.esp-react {
    animation: rota-react 18s linear infinite;
}

.fa-react {
    animation-delay: 0.8s;
}

.fa-html5 {
    animation-delay: 0.5s;
}
.fa-css3 {
    animation-delay: 0.1s;
}
.fa-js {
    animation-delay: 0.9s;
}


.img-tech {
    width: 5rem;
    height: 5rem;
    object-fit: cover;
}

@keyframes animate {
    from {
        filter: hue-rotate(0deg);
    }
    to {
        filter: hue-rotate(360deg);
    }
}

@keyframes rota-react {
    from {
        transform: rotate(0deg);
        filter: hue-rotate(0deg);
    }
    to {
        transform: rotate(360deg);
        filter: hue-rotate(360deg);
    }
}
