.App {
  display: grid;
  grid-template-rows: auto 1fr auto;
}

.container {
  max-width: 100vw;
  width: 100vw;
  overflow: hidden scroll;
}

