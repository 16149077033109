.single-card {
    align-items: center;
    border-radius: 10px;
    border: 1px solid rgba(0,0,0,.125);
    box-shadow: 3px 4px 18px -1px rgb(0 0 0 / 75%);
    width: 70%;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    margin: 1rem;
}

.card-img {
    height: 25vh;
    width: 100%;
    overflow: hidden;
    box-shadow: 1px 1px 4px rgb(0 0 0 / 75%);
    filter: grayscale(60%);
}

.card-body {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    padding: 1.5rem;

}
    
.pro-title, 
.pro-desc {
    text-align: center;
    margin: 1rem 0;
} 

.pro-title {
    font-size: 1.3rem;
    font-weight: 400;
}

@media screen and (min-width: 768px){
    .single-card {
        width: 40%;
    }
    
    .card-img {
        height: 250px;
    }
}
