.btn-visit {
    background-color: #167BFF;
    color: #ffc;
    border-radius: 5px;
    border: none;
    font-size: 1.1rem;
    padding: .5rem 1rem;
    cursor: pointer;
    width: 50%;
    text-align: center;
}

.btn-visit:hover {
    background-color: #0b57b9;
}

.btn-visit:active {
    transform: scale(90%);
}