.main-info {
    background-color: var(--main-bg-color);
    display: grid;
    align-content: center;
    min-height: 30vh;
    height: 30vh;
    
}

.main-info-h1 {
    color: var(--main-font-color);
    font-family: 'Montserrat', sans-serif;
    font-size: clamp(2rem, 0.75rem + 1.5vw, 2.4rem);
    text-align: center;
    text-transform: capitalize;
    font-weight: 500;
}

.main-info-p {
    color: var(--main-font-color);
    font-family: 'Montserrat', sans-serif;
    font-size: clamp(1.5rem, 0.75rem + 1.5vw, 1.4rem);
    font-weight: 300;
    text-align: center;
}