.hero {
    display: grid;
    place-items: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-blend-mode: color-burn;
    height: 30vh;
    min-height: 30vh;
    width: 100vw;
}

.hero-h1 {
    color: red;
    font-size: calc(1rem + 5vw);
    font-weight: 500;
    font-family: 'Montserrat', sans-serif;
}

.hero-h1:before {
    content: '{  ';
}

.hero-h1:after {
    content: '  }';
}

@media screen and (min-width: 768px) {

    .hero {
        height: 100vh;
    }

}