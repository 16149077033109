.project-cards {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto 1fr;
    margin: 2rem 0;
    place-items: center;
    width: 100vw;
}

.project-cards-title {
    color: var(--main-font-color);
    font-family: 'Montserrat', sans-serif;
    font-size: 2.25rem;
    text-transform: capitalize;

}

.cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1000px;
    flex-direction: row;
}